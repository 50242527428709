/* dropzone */
.upload-container {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}

.upload-container > p {
  font-size: 1rem;
}

.upload-container > em {
  font-size: .8rem;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  width: 100%;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone.disabled {
  opacity: 0.6;
}

/* modal */

.customOverlay {
  /* background: rgba(36, 123, 160, 0.7); */
  z-index: 4999;
}

.customModalDialog {
  max-width: 50vw;
  max-height: 76vh;
  margin-top: 40px;
}

.customModalContent {
  width: 50vw;
  height: 76vh;
  /* height: 100%;
  max-height: 76vh; */
  border-radius: 33px;
  /* background-color: #F7F7F7; */
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px;
  height: 100%;
}

.modal-header {
  border-bottom: 0 none;
  padding: 0;
}

.modal-footer {
  border-top: 0 none;
}

/* .modal-open {
  overflow: inherit;
} */

.modal-open {
  overflow:hidden;
  /* overflow-y:scroll; */
  overflow-y:hidden;
  padding-right:0 !important;
}